import React from "react";
import Card from "./card";

const StaffList = ({staff, keyBase, limit}) => (
  <>
    {staff.map((item, i) => (
      i < (limit || staff.length) && <Card isStaff={true} key={`${keyBase}-${i}`} image={item.featureImage[0]} ar={1/1} hasFocalPoint={item.featureImage.length > 0 ? item.featureImage[0].hasFocalPoint : false} title={item.displayTitle} subtitle={item.position} href={item.bodyContent.length > 0 && `/${item.uri}`}/>
    ))}
  </>
);

export default StaffList;